<template>
    <li class="nav-item dropdown ">
        <a :class="tipo_servicio ? 'nav-link active' : 'nav-link'" data-toggle="dropdown" href="#">
            <i class="far fa-bell"></i>
          <span class="badge badge-danger ">{{numero_avisos}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="height: auto; max-height: 700px; overflow-x: hidden; min-width: 400px;">
          <div class="dropdown-divider"></div>
          <ListaAvisos :avisos="avisos" v-on:evento_expediente_avisos_tipo="cargarExpedienteAvisos"/>
        </div>
    </li>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import ListaAvisos from './AvisosLista.vue';


export default({
    components:{
        ListaAvisos
    },
    props:{
        tipo_servicio: String
    }
    ,data() {
        return {
            avisos: [],
            numero_avisos: '',
        }
    },
    methods: {
        async cargardatos() {
            /* get  modulos/pwgsapi/index.php/avisos */
            const api = new PwgsApi;
            const datos = await api.post('avisos');
            this.avisos = datos.datos;
            this.numero_avisos = datos.n_total_avisos;
            console.log('avss', this.avisos);
        },

        cargarExpedienteAvisos: function(tipo){
            //alert(tipo);
            console.log("tipo", tipo);
            localStorage.setItem("tipobuscador", tipo);
            this.$router.push({ name: 'Escritorio', params: { tipo_buscador: tipo } })
            
           // this.$emit('evento_expediente_avisos_tipo', tipo); //Es llamado a ./Escritorio.vue
        }

    },
    mounted() {
        this.cargardatos();
    }
})
</script>
