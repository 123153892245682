<template>
    <a  href="#" class="dropdown-item"
        v-for="solicitud in solicitudes" :key="solicitud.id_aviso">
        <div v-if="solicitud.estado_servicio!='activo'" data-toggle="tooltip" data-placement="top" style="background-color: #F89393;display:flex; overflow-x:auto; justify-content:space-between">
            <span style="width: 100%;"><i :class="solicitud.class" :style="solicitud.style" :title="solicitud.tipo"></i>
            <b v-tooltip="extractContent(solicitud.direccion_cliente)" @click.prevent="cargarExpediente(solicitud.id_servicio)">{{solicitud.codigo_servicio}}</b></span>
            <span style="width: 100%; text-align:center">{{ solicitud.nombre_tramitador }}</span>
            <span style="width: 100%; text-align:end" class="text-muted text-sm">{{solicitud.fecha_hora}} <i @click="eliminarsolicitud(solicitud.id_aviso)" style="color:red" class="fa fa-window-close"></i></span>
        </div>
        <div v-else data-toggle="tooltip" data-placement="top" style="display:flex; overflow-x:auto; justify-content:space-between">
            <span style="width: 100%;"><i :class="solicitud.class" :style="solicitud.style" :title="solicitud.tipo"></i>
            <b v-tooltip="extractContent(solicitud.direccion_cliente)" @click.prevent="cargarExpediente(solicitud.id_servicio)">{{solicitud.codigo_servicio}}</b></span>
            <span style="width: 100%; text-align:center">{{ solicitud.nombre_tramitador }}</span>
            <span style="width: 100%; text-align:end" class="text-muted text-sm">{{solicitud.fecha_hora}} <i @click="eliminarsolicitud(solicitud.id_aviso)" style="color:red" class="fa fa-window-close"></i></span>
        </div>
    </a>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default({
    props:{
        solicitudes: Array
    },
    setup() {
        
    },
 
    methods: {
        cargarExpediente(id){
            localStorage.setItem("tiposerv", 'solicitud');
            this.$router.push({
                name: 'Servicio',
                params: {
                    id:id
                }
            });
        },
       async eliminarsolicitud(id_aviso) {
            //put pwgsapi/index.php/avisos/revisar-avisos
           const api = new PwgsApi();
           let ids = [];
           ids.push(id_aviso);
        let subidadatos = {tipo:'solicita',ids_avisos:ids,estado:1,}
           await api.put('avisos/revisar-avisos', subidadatos);
           this.$parent.cargarsolicitudes();
        },
        extractContent(s) {
            var span = document.createElement('span');
            span.innerHTML = s;
            try {
                return decodeURIComponent(escape(span.textContent)) || decodeURIComponent(escape(span.innerText));
            }
            catch (error) {
                console.log(error);
                return s;
            }
        },
    }  
})
</script>
